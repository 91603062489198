import React, { useState, useEffect } from 'react';
import { BsInstagram, BsTwitterX, BsFacebook, BsLinkedin } from "react-icons/bs";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if the screen size is less than 700px
  const isMobile = windowWidth < 850;

  // Define styles for responsive adjustments
  const responsiveTextStyle = isMobile ? 'text-lg' : 'text-xl';
  const responsiveIconStyle = isMobile ? 'w-8 h-8' : 'w-10 h-10';

  return (
    <div className="bg-blue-950 mt-12">
      <div className="flex items-center justify-center pt-12">
        <img className="h-20 w-auto rounded-lg" src="./jcsgl.png" alt="" />
      </div>
      <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-center pt-8`}>
        <NavLink to="/about-us" className={`px-4 text-white font-semibold ${responsiveTextStyle} ${isMobile ? 'pb-4' : ''}`}>About Us</NavLink>
        <NavLink to="/contact-us" className={`px-4 text-white font-semibold ${responsiveTextStyle} ${isMobile ? 'pb-4' : ''}`}>Contact Us</NavLink>
      </div>

      <div className="flex justify-center space-x-10 pt-10 pb-10 border-b">
      <a href="https://www.facebook.com/jcserviceshub" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsFacebook className={`hover:text-[#2f29d6] ${responsiveIconStyle} text-white`} />
      </a>
      <a href="https://www.instagram.com/jcs.hub/" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsInstagram className={`hover:text-[#d62976] ${responsiveIconStyle} text-white`} />
      </a>
      <a href="https://www.linkedin.com/company/jcs-hub/" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsLinkedin className={`hover:text-[#0077b5] ${responsiveIconStyle} text-white`} />
      </a>
      <a href="https://x.com/jcshub?lang=en" target="_blank" rel="noopener noreferrer" className="card shadow-2xl">
        <BsTwitterX className={`hover:text-[#00acee] ${responsiveIconStyle} text-white`} />
      </a>
    </div>
      <div className="py-10 text-gray-300">
        <h1 className={`${responsiveTextStyle}`}>©2020 JCS Global. All Rights Reserved | Caution Notice</h1>
      </div>
      <div className="flex justify-center pb-10">
        <h1 className={`text-2xl text-white px-6 ${responsiveTextStyle}`}>JCS Group</h1>
        <h1 className={`text-2xl text-white px-6 ${responsiveTextStyle}`}>Carry Carter</h1>
        <h1 className={`text-2xl text-white px-6 ${responsiveTextStyle}`}>JCS Hub</h1>
      </div>
    </div>
  );
};

export default Footer;
