import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';

const Man = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-12 md:pt-20">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./man1.webp" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold">Man Power Supply</h1>
        </div>
      </div>

      <div className="pt-14 md:pt-20 p-4 md:p-20 flex flex-wrap justify-center items-center">
        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl py-8 md:py-4">Temporary Staffing</h1>
            <p className="text-base md:text-xl p-2 md:p-5">These services provide businesses with temporary employees for short-term assignments or to cover peak workloads. Temporary workers are often used to meet seasonal demands, project-based work, or to provide temporary replacements for regular employees.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl py-8 md:py-4">Contract Staffing</h1>
            <p className="text-base md:text-xl p-2 md:p-5">Contract staffing involves providing skilled professionals or specialized workers on a contractual basis for specific projects. These workers may have expertise in areas like IT, engineering, healthcare, or other specialized fields.</p>
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl py-8 md:py-4">Recruitment and Selection</h1>
            <p className="text-base md:text-xl p-2 md:p-5">Manpower supply agencies can assist in recruiting and selecting the right candidates for permanent positions within a company. They may handle job posting, candidate screening, interviews, and reference checks.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Man;
