import React from 'react'
import Fixed from './Fixed';
import Footer from './Footer';

const Electronics = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-14 md:pt-20">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./electronics1.jpg" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-violet-600 font-bold">Electronics</h1>
        </div>
      </div>

      <div className="p-6 md:p-10 m-4 md:m-28 md:px-20 border rounded-2xl hover:shadow-2xl">
        <h1 className="text-xl md:text-2xl font-bold">Our Products exhibit the functionality and performance traits similar to top-tier electronic components. While they are not entirely equivalent to premium-grade products, their specifications closely match industry standards for cutting-edge electronic applications.</h1>
      </div>

    

      <Footer />
    </div>
  )
}

export default Electronics;