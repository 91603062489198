import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Plumbing = () => {
  const plumbingCategories = [
    { src: './pipe2.webp', alt: 'pipe2', text: 'Water Tanks (3027)' },
    { src: './pipe3.webp', alt: 'pipe3', text: 'Pipe Fittings (227)' },
    { src: './pipe4.webp', alt: 'pipe4', text: 'Pipes (257)' },
    { src: './pipe5.webp', alt: 'pipe5', text: 'Tube Pipes (187)' },
  ];

  const plumbingItems = [
    { src: './pipe6.webp', alt: 'pipe6', name: 'TATA DI Pipe K-9 - 350 mm', price: '' },
    { src: './pipe7.webp', alt: 'pipe7', name: 'Astral PVC Agri Pipe - 160 mm - 20 ft', price: '' },
    { src: './pipe4.webp', alt: 'pipe4', name: 'Neco CI Hubless Pipe - 50 mm', price: '' },
    { src: './pipe6.webp', alt: 'pipe6', name: 'Astral UPVC Socket - 160 mm', price: '' },
    { src: './pipe5.webp', alt: 'pipe5', name: 'Neco CI Hubless Bend - 90 Deg - 150 mm', price: '' },
  ];

  return (
    <div>
      <Fixed />

      {/* Header Section */}
      <div className="pt-20 md:pt-40">
        <div className="relative" style={{ height: '700px' }}>
          <img
            className="opacity-60 w-full h-full object-cover"
            src="./pipe1.webp"
            alt="sanitary"
          />
          <h1 className="text-2xl md:text-4xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-800 font-bold">
            Plumbing Pipes & Fittings
          </h1>
        </div>
      </div>

      {/* Categories Section */}
      <div className="p-4 sm:p-14 pt-14 md:pt-28 flex flex-wrap justify-center gap-4">
        {plumbingCategories.map(({ src, alt, text }, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center border rounded-xl p-4 md:p-6 hover:shadow-2xl w-32 md:w-48"
          >
            <img
              className="w-16 md:w-40 h-16 md:h-40 rounded-full border-2 border-black mb-4"
              src={src}
              alt={alt}
            />
            <p className="text-sm md:text-lg font-semibold">{text}</p>
          </div>
        ))}
      </div>

      {/* Products Section */}
      <div className="flex flex-col pt-10 md:pt-20 items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full md:w-3/4">
          {plumbingItems.map(({ src, alt, name, price }, index) => (
            <div
              key={index}
              className="flex flex-col border p-3 md:p-6 hover:shadow-2xl cursor-pointer"
            >
              <img className="h-40 md:h-96 w-full mb-2" src={src} alt={alt} />
              <h1 className="text-sm md:text-lg font-semibold mb-2">{name}</h1>
              <p className="text-sm md:text-base font-semibold mb-2">{price}</p>
              <NavLink
                to="/service"
                className="bg-blue-950 text-white py-1 px-4 md:py-3 md:px-6 text-sm md:text-lg font-semibold rounded-lg text-center"
              >
                Request One
              </NavLink>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Plumbing;