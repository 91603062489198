import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Steel = () => {
  const steelItems = [
    { src: "./steel5.webp", alt: "steel5", name: "Primary Make HR Sheet - 1.6 mm x 1250 mm x 2500 mm", price: "" },
    { src: "./steel6.webp", alt: "steel6", name: "VSP Make MS Black Bar - 25 mm Dia", price: "" },
    { src: "./steel7.webp", alt: "steel7", name: "ISI/Rolling Make MS Channel - 125 mm x 75 mm", price: "" },
    { src: "./steel8.webp", alt: "steel8", name: "German TMX TMT Rebar Fe550D", price: "" },
    { src: "./steel9.webp", alt: "steel9", name: "JSPL HR Coil - 8 mm x 1000 mm", price: "" },
    { src: "./steel10.webp", alt: "steel10", name: "Apollo Hollow Section ERW Pipes - 300 mm x 200 mm x 8 mm, YST 210 , 6 Mtr Length", price: "" },
  ];

  const steelCategories = [
    { src: "./Steel2.webp", alt: "steel2", text: "Structural Steel (27)" },
    { src: "./Steel3.webp", alt: "steel3", text: "Rebar (17)" },
    { src: "./Steel4.webp", alt: "steel4", text: "Raw Material steel (57)" },
    { src: "./steel5.webp", alt: "steel5", text: "Fittings (187)" },
  ];

  return (
    <div>
      <Fixed />
      <div className="pt-12 md:pt-20">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./steel1.webp" alt='steel' />
          <h1 className="text-4xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold">Steel</h1>
        </div>
      </div>

      <div className="p-4 sm:p-14 pt-12 md:pt-12 flex flex-wrap justify-center">
        {steelCategories.map(({ src, alt, text }, index) => (
          <div key={index} className="rounded-full flex flex-col mx-2 md:mx-6 mb-4 md:mb-4">
            <img className="w-16 md:w-40 h-16 md:h-40 rounded-full border-2 border-black mx-auto mb-2" src={src} alt={alt} />
            <p className="text-xs md:text-lg text-center font-semibold">{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col pt-10 md:pt-10 items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 w-full md:w-2/3">
          {steelItems.map(({ src, alt, name, price }, index) => (
            <div key={index} className='flex flex-col border p-2 md:p-4 hover:shadow-2xl cursor-pointer m-1 md:m-2'>
              <img className='h-40 md:h-96 w-full' src={src} alt={alt} />
              <h1 className="text-sm md:text-xl font-semibold p-1 md:p-2">{name}</h1>
              <p className="text-sm md:text-xl font-semibold p-1 md:p-2">{price}</p>
              <NavLink to="/service" className="bg-blue-950 text-white p-2 md:p-3 text-sm md:text-xl font-semibold rounded-xl">Request One</NavLink>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Steel;
