import React from 'react';

const Connect = () => {
  // Function to handle the contact action based on device type
  const handleContact = () => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobileDevice) {
      // Initiate a phone call
      window.location.href = 'tel:+91 9090007108';
    } else {
      // Open WhatsApp with predefined message
      window.location.href = 'https://wa.me/919090007108?text=Hello%20there!';
    }
  };

  return (
    <div className="pt-16 sm:pt-24 px-4">
      <h1 className="text-2xl sm:text-4xl font-bold py-8 sm:py-12 text-center">Connect With Us</h1>
      <div className="flex flex-col sm:flex-row justify-evenly items-center">
        <button 
          onClick={handleContact} 
          className="bg-blue-950 text-white text-lg sm:text-3xl m-4 sm:m-6 w-full sm:w-1/4 h-12 sm:h-16 rounded-2xl hover:scale-105 flex items-center justify-center"
        >
          Call Us
        </button>
        <a 
          href="https://wa.me/919090007108?text=Hello%20there!" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="bg-blue-950 text-white text-lg sm:text-3xl m-4 sm:m-6 w-full sm:w-1/4 h-12 sm:h-16 rounded-2xl hover:scale-105 flex items-center justify-center"
        >
          Chat With Us
        </a>
      </div>
    </div>
  );
};

export default Connect;
