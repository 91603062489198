import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Cement = () => {
  const cementItems = [
    { src: "./cement1.webp", alt: "cement1", text: "cement (37)" },
    { src: "./cement2.webp", alt: "cement2", text: "Aggregates (13)" },
    { src: "./cement3.webp", alt: "cement3", text: "Ready Mix plaster (5)" },
    { src: "./cement4.webp", alt: "cement4", text: "Ready Mix concreate (18)" },
  ];

  const cementProducts = [
    { src: "./cement5.webp", alt: "cement5", name: "Terrain Coir Mat 450 GSM - 50 Mtr - Green", price: "" },
    { src: "./cement6.webp", alt: "cement6", name: "JK Super Ordianry Portland Cement 53", price: "" },
    { src: "./cement7.webp", alt: "cement7", name: "Tavara Water Wash Wall Concrete M - Sand", price: "" },
    { src: "./cement8.webp", alt: "cement8", name: "WMM Aggregates", price: "" },
    { src: "./cement9.webp", alt: "cement9", name: "Tavara Manufactured Sand", price: "" },
    { src: "./cement10.webp", alt: "cement10", name: "KJS Cement OPC 43 Bulk", price: "" },
  ];

  return (
    <div>
      <Fixed />
      <div className="pt-20 md:pt-40">
        <div className='relative' style={{ height: '700px' }}>
          <img className="opacity-60 w-full h-full" src="./cement1.webp" alt='cement' />
          <h1 className="text-2xl md:text-4xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-800 font-bold">Cement & Allied Products</h1>
        </div>
      </div>

      <div className="p-4 sm:p-14 pt-14 md:pt-28 flex flex-wrap justify-center">
        {cementItems.map(({ src, alt, text }, index) => (
          <div key={index} className="rounded-s-full flex flex-col mx-2 md:mx-6 mb-4 md:mb-8">
            <img className="w-16 md:w-40 h-16 md:h-40 rounded-full border-2 border-black mx-auto mb-2" src={src} alt={alt} />
            <p className="text-xs md:text-lg text-center font-semibold">{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center pt-10 md:pt-14">
        <div className="flex flex-wrap items-center justify-center w-full md:w-3/4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-4">
            {cementProducts.map(({ src, alt, name, price }, index) => (
              <div key={index} className="flex flex-col border p-2 md:p-5 hover:shadow-2xl cursor-pointer m-1 md:m-2">
                <img className="h-40 md:h-96 w-full" src={src} alt={alt} />
                <h1 className="text-sm md:text-lg font-semibold p-1 md:p-3">{name}</h1>
                <p className="text-sm md:text-lg font-semibold p-1 md:p-4">{price}</p>
                <NavLink to="/service" className="bg-blue-950 text-white p-2 md:p-4 text-sm md:text-lg font-semibold rounded-xl">Request One</NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cement;
