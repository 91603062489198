import React from 'react';
import { NavLink } from 'react-router-dom';

const Fixed = () => {
  return (
    <div className="fixed z-10 w-full bg-white shadow-md">
      <div className="flex flex-row items-center justify-between px-4  md:px-6">
        <div className="">
          <img className="h-12 w-auto md:h-20 rounded-md" src="/jcsgl.png" alt="" />
        </div>
       
        <div className="flex items-center space-x-4 md:space-x-6">
          <NavLink to="/" className="text-sm md:text-xl font-bold px-2 md:px-4" activeClassName="text-blue-600">Home</NavLink>
          <NavLink to="/about-us" className="text-sm md:text-xl font-bold px-2 md:px-4 hidden lg:block" activeClassName="text-blue-600">About Us</NavLink>
          <NavLink to="/shop-by-catagories" className="text-sm md:text-xl font-bold px-2 md:px-4" activeClassName="text-blue-600">Categories</NavLink>

          <div className="p-1 md:p-2 hidden lg:block">
            <img className="h-12 w-auto md:h-20 rounded-md" src="./jcsgr.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fixed;
