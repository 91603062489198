import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';

const Oil = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-12 md:pt-20">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./oil1.jpg" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold">Oil Trade & Oil Refineries</h1>
        </div>
      </div>

      <div className="p-6 md:p-10 m-4 md:m-28 md:px-20 border rounded-2xl hover:shadow-2xl">
        <h1 className="text-xl md:text-2xl font-bold">Our “Industrial Power Oil” & “Industrial White Oil“ have the nature and properties of diesel but it’s not 100% diesel though specifications may match with HDS.</h1>
      </div>

      <div className="px-4 md:px-20 flex flex-wrap justify-center items-center">
        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 rounded-xl hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl pt-4 md:pt-14 pb-3">Furnace Oil</h1>
            <h1 className="text-lg md:text-xl p-2 md:p-4">Importer/ Trader</h1>
            <h1 className="text-lg md:text-xl p-1 md:p-2">Specification</h1>
            <img className="pt-4" src="./oil2.webp" alt=' ' />
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 rounded-xl hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl pt-4 md:pt-14 pb-3">Industrial Oil</h1>
            <h1 className="text-lg md:text-xl p-2 md:p-4">Manufacturer</h1>
            <h1 className="text-lg md:text-xl p-1 md:p-2">Specification</h1>
            <img className="pt-4" src="./oil3.webp" alt=' ' />
          </div>
        </div>

        <div className="flex border h-auto w-full sm:w-96 m-2 md:m-6 rounded-xl hover:shadow-2xl cursor-pointer">
          <div className="p-4">
            <h1 className="font-bold text-xl md:text-2xl pt-4 md:pt-14 pb-3">Fuel Oil</h1>
            <h1 className="text-lg md:text-xl p-2 md:p-4">Trader</h1>
            <h1 className="text-lg md:text-xl p-1 md:p-2">Specification</h1>
            <img className="pt-4" src="./oil4.webp" alt=' ' />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Oil;
