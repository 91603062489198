import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Fixed Header */}
      <Fixed />

      {/* Header Section */}
      <div className="pt-20 sm:pt-32 bg-blue-950 text-white text-center">
        <h1 className="text-3xl sm:text-5xl font-bold py-10">About Us</h1>
      </div>

      {/* Content Section */}
      <div className="flex-grow px-4 sm:px-16 pt-10 sm:pt-20">
        <p className="text-base sm:text-lg text-gray-700 leading-relaxed text-center sm:text-left max-w-4xl mx-auto">
          Our mission is to build the most trusted B2B e-commerce platform that helps SMEs unlock their potential by
          transforming the way they do their business. We empower contractors and retailers to procure high-quality
          construction raw materials from top brands at competitive prices, with easy access to finance and a smooth
          delivery experience. Our platform also provides suppliers with the opportunity to grow their market and
          increase capacity utilization. We are a technology-first brand that is digitizing the entire ecosystem, making
          it more efficient, transparent, and accessible for businesses.
        </p>
      </div>

      {/* Empty Space (Optional) */}
      <div className="flex-grow"></div>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default AboutUs;
