import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NavLink } from 'react-router-dom';

const Firstimage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: dots => (
      <div className="absolute bottom-5 left-0 right-0 flex justify-center">
        <ul className="flex justify-center space-x-2">{dots}</ul>
      </div>
    ),
    customPaging: i => <div className="h-3 w-3 bg-gray-700 rounded-full" />,
  };

  return (
    <div className="pt-20">
      <Slider {...settings}>
        {[
          {
            image: './front6.jpg',
            heading: 'From Steel to Tiles',
            description: 'Explore our comprehensive selection of building materials available online, ideal for residential, commercial, and infrastructure projects alike.',
            subheading: '18000+ SKUs | 150+ Brands',
          },
          {
            image: './front2.jpg',
            heading: 'Refining Your Goals',
            description: 'Integrated Procurement Solutions with Financing Options for Building Materials.',
            subheading: 'Competitive Pricing | Timely Deliveries | Ensured Quality',
          },
          {
            image: './front5.jpg',
            heading: 'Tailored Solutions',
            description: 'Optimizing Supply Chains: Navigating the Logistics Landscape',
            subheading: 'Multiple programs | Easy Eligibility check | Collateral-free credit line',
          },
        ].map((slide, index) => (
          <div key={index} className="relative w-full h-auto">
            <img className="w-full h-auto" src={slide.image} alt={`Slide ${index + 1}`} />
            <div
              className={`absolute ${isMobile ? 'top-2' : 'top-0'} left-0 bottom-0 ${
                isMobile ? 'w-3/4' : 'w-1/2'
              } h-full flex flex-col justify-center items-start`}
            >
              <div className={`p-4 rounded-xl bg-opacity-80 bg-white ${isMobile ? 'py-8' : 'py-12'}`}>
                <h1 className={`text-cyan-800 ${isMobile ? 'text-2xl' : 'text-4xl'} font-bold`}>
                  {slide.heading}
                </h1>
                <p className={`text-gray-700 ${isMobile ? 'text-sm' : 'text-xl'} pt-5`}>
                  {slide.description}
                </p>
                <h2 className={`text-gray-700 ${isMobile ? 'text-sm' : 'text-xl'} pt-5`}>
                  {slide.subheading}
                </h2>
                <div className="pt-6">
                  <NavLink
                    to="/service"
                    className={`bg-gradient-to-r from-pink-300 via-blue-500 to-sky-300 px-4 py-2 rounded-xl hover:scale-105 ${
                      isMobile ? 'text-sm' : 'text-xl'
                    } text-white`}
                  >
                    Get Service
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Firstimage;
