import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';

const Sanitaryware = () => {
  const sanitaryItems = [
    { src: "./sanitary5.webp", alt: "san5", name: "Grohe Grandera 17mm Gold", price: "" },
    { src: "./sanitary6.webp", alt: "san6", name: "Grohe Grandera 28mm Gold", price: "" },
    { src: "./sanitary7.webp", alt: "san7", name: "Grohe Grandera 13mm ", price: "" },
    { src: "./sanitary8.webp", alt: "san8", name: "Grohe Grandera 17mm Gold", price: "" },
    { src: "./sanitary9.webp", alt: "san9", name: "Grohe Grandera 17mm ", price: "" },
    { src: "./sanitary10.webp", alt: "san10", name: "Grohe Grandera 17mm ", price: "" },
  ];

  return (
    <div>
      <Fixed />
      <div className="pt-20 md:pt-40">
        <div className='relative' style={{ height: '500px' }}>
          <img className="opacity-80 w-full h-full object-cover" src="./sanitary.webp" alt='sanitary' />
          <h1 className="text-3xl md:text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-800 font-bold">Sanitaryware & Bath Fittings</h1>
        </div>
      </div>

      <div className="p-4 sm:p-14 pt-14 md:pt-28 flex flex-wrap justify-center">
        {[
          { src: "./Sanitary1.webp", alt: "san1", text: "Bath Fittings (307)" },
          { src: "./Sanitary2.webp", alt: "san2", text: "Washbasin (27)" },
          { src: "./Sanitary3.webp", alt: "san3", text: "Toilets (57)" },
          { src: "./Sanitary4.webp", alt: "san4", text: "Bath Fittings (17)" },
        ].map(({ src, alt, text }, index) => (
          <div key={index} className="rounded-full flex flex-col mx-2 md:mx-6 mb-4 md:mb-8">
            <img className="w-16 md:w-40 h-16 md:h-40 rounded-full border-2 border-black mx-auto mb-2" src={src} alt={alt} />
            <p className="text-xs md:text-lg text-center font-semibold">{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col pt-10 md:pt-20 items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 w-full md:w-2/3">
          {sanitaryItems.map(({ src, alt, name, price }, index) => (
            <div key={index} className='flex flex-col border p-2 md:p-5 hover:shadow-2xl cursor-pointer m-1 md:m-2'>
              <img className='h-40 md:h-96 w-full' src={src} alt={alt} />
              <h1 className="text-sm md:text-xl font-semibold p-1 md:p-3">{name}</h1>
              <p className="text-sm md:text-xl font-semibold p-1 md:p-4">{price}</p>
              <NavLink to="/service" className="bg-blue-950 text-white p-2 md:p-4 text-sm md:text-xl font-semibold rounded-xl">Request One</NavLink>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Sanitaryware;
