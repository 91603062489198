import React from 'react';
import Marquee from "react-fast-marquee";

const Brands = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center pt-20 sm:pt-32 px-4">
      {/* Header Section */}
      <div className="w-full flex flex-col">
        <div className="flex flex-col text-center mb-8 sm:mb-12 pb-4 sm:pb-6">
          <h2 className="text-2xl sm:text-4xl font-bold">Our Brands</h2>
        </div>
        
        {/* Marquee Section */}
        <Marquee gradient={false} speed={50} pauseOnHover>
          <img className="h-12 sm:h-20 w-auto mx-4" src='./jcsgr.png' alt='JCSGR' />
          <img className="h-12 sm:h-16 w-auto mx-4" src='./carry.png' alt='Carry' />
          <img className="h-12 sm:h-14 w-auto mx-4" src='./srirama.png' alt='Srirama' />
          <img className="h-14 sm:h-20 w-auto mx-4" src='./sunotal.png' alt='Sunotal' />
          <img className="h-12 sm:h-20 w-auto mx-4" src='./jtv.png' alt='JTV' />
          <img className="h-12 sm:h-20 w-auto mx-4" src='./jpay1.png' alt='JPay1' />
          <img className="h-12 sm:h-20 w-auto mx-4" src='./jfarm.jpg' alt='jfarm' />
          <img className="h-12 sm:h-20 w-auto mx-4" src='./jfc.jpg' alt='jfc' />
        </Marquee>
      </div>
    </div>
  );
};

export default Brands;
