import React from 'react';
import { NavLink } from 'react-router-dom';

const Catagories = () => {
  return (
    <div className="pt-12 sm:pt-28 px-4 lg:px-20">
      <div>
        <h1 className="text-2xl sm:text-4xl font-bold pb-3 text-center">Our Services</h1>
      </div>
      <div className="relative pt-4">
        <div className="flex flex-wrap justify-around items-center">
          {[
            { to: "/electronics", src: "/electronics.avif", alt: "Electronics", label: "Electronics" },
            { to: "/it", src: "/software.jpg", alt: "IT & Software", label: "IT & Software" },
            { to: "/oil", src: "/oil.jpg", alt: "Oil Trade & Oil Refineries", label: "Oil Trade & Oil Refineries" },
            { to: "/man", src: "/man.jpg", alt: "Man Power Supply", label: "Man Power Supply" },
            { to: "/sanitaryware", src: "/sanitary.webp", alt: "Sanitaryware & Bathfittings", label: "Sanitaryware & Bathfittings" },
            { to: "/door", src: "/door.jpg", alt: "Doors & Windows", label: "Doors & Windows" },
            { to: "/tiles", src: "/tiles.jpg", alt: "Tiles & Surfaces", label: "Tiles & Surfaces" },
            { to: "/plumbing", src: "/plumbings.jpg", alt: "Plumbing pipes & Fittings", label: "Plumbing pipes & Fittings" },
            { to: "/cement", src: "/cement.jpg", alt: "Cements & Allied", label: "Cements & Allied" },
            { to: "/steel", src: "/steel.jpg", alt: "Steel", label: "Steel" },
            
          ].map((item, index) => (
            <NavLink
              key={index}
              to={item.to}
              className="relative m-2 sm:m-3 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 h-40 sm:h-48 lg:h-52 rounded-lg border border-gray-300 overflow-hidden hover:scale-105 transition-transform cursor-pointer"
            >
              <img className="w-full h-full object-cover" src={item.src} alt={item.alt} />
              <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-center">
                <h1 className="text-sm sm:text-lg lg:text-xl text-white py-2">{item.label}</h1>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Catagories;
