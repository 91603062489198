import React from 'react';
import Fixed from './Fixed';
import Footer from './Footer';
import { UilEnvelope, UilPhone, UilWhatsappAlt } from '@iconscout/react-unicons';

const Contact = () => {
  return (
    <div>
      <Fixed />
      <div className="pt-20 sm:pt-32 bg-blue-950 text-white text-center">
        <h1 className="text-3xl sm:text-4xl font-bold py-10">Contact Us</h1>
      </div>
      
      <div className="flex flex-col justify-center items-center pt-32 sm:pt-40 px-4 sm:px-0">
        <div className="border-2 rounded-md w-full sm:w-3/5 flex flex-col items-start p-5 m-6 sm:m-12">
          <h1 className="text-xl sm:text-xl font-semibold pl-2 sm:pl-8 pb-4">Connect with us :</h1>
          <div className="flex items-center p-2 pl-2 sm:pl-8">
            <UilEnvelope className="h-6 w-6 sm:h-8 sm:w-8" /> 
            <p className="pl-4 text-base sm:text-lg font-semibold">contact@jcsglobal.in</p>
          </div>
          <div className="flex items-center p-2 pl-2 sm:pl-8">
            <UilPhone className="h-6 w-6 sm:h-8 sm:w-8" /> 
            <p className="pl-4 text-base sm:text-lg font-semibold">91-9090007108</p>
          </div>
          <div className="flex items-center p-2 pl-2 sm:pl-8">
            <UilWhatsappAlt className="h-6 w-6 sm:h-8 sm:w-8" /> 
            <p className="pl-4 text-base sm:text-lg font-semibold">91-9090007108</p>
          </div> 
        </div>
        <div className="border-2 rounded-md w-full sm:w-3/5 flex flex-col items-start p-5 m-6 sm:m-12">
          <h1 className="text-xl sm:text-2xl font-semibold pl-2 sm:pl-8 pb-4">Corporate Office:</h1>
          <div className="flex flex-col items-start justify-start pl-4 sm:pl-9">
            <h1 className="text-xl sm:text-2xl font-bold pb-4">JCS Global</h1>
            <p className="text-base sm:text-xl font-semibold pb-1">76-16-53, Urmila Nagar,</p>
            <p className="text-base sm:text-xl font-semibold pb-1">Bhavanipuram,</p>
            <p className="text-base sm:text-xl font-semibold pb-1">Vijayawada, A.p .</p>
          </div>
        </div>
      </div>
      <div className="pt-32 sm:pt-48">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
